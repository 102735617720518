import { FC, useState, useEffect, useMemo } from 'react';
import { addPeriodicReportXlsFileRequest, getXlsPeriodicReportFile } from 'api/services';
import { downloadFile } from 'lib/utils';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import styles from './AsyncDownload.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';
import { PushNotification, NotificationType, PeriodicReportGeneratedData } from '../../../../App';
import { AsyncDownloadProps, REQUEST_STATE } from './AsyncDownload.types';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ApiResponse, PROCESSING_STATE } from 'api/models';
import { AxiosResponse } from 'axios';
import { showErrorToast } from 'lib/utils/showToast';

const xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const tooltipMessage =
  'Trwa generowanie pliku. Może to potrwać około 30 sekund. Nie odświeżaj strony. Plik zostanie następnie zapisany na Twoim urządzeniu.';

const AsyncDownload: FC<AsyncDownloadProps> = ({ reportId }) => {
  const [requestId, setRequestId] = useState<string>();
  const [requestState, setRequestState] = useState(REQUEST_STATE.IDLE);
  const connection = useSelector(connectionSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    connection?.on('SendNotification', (message: PushNotification<PeriodicReportGeneratedData>) => {
      if (message?.type === NotificationType.UserPeriodicReportGenerated) {
        if (message?.data?.requestId === requestId) {
          setRequestState(REQUEST_STATE.READY);
          downloadReport();
        }
      }
    });
  }, [connection, requestId]);

  const requestReport = async () => {
    let requestId = uuid();
    setRequestId(requestId);
    setRequestState(REQUEST_STATE.PENDING);

    try {
      await addPeriodicReportXlsFileRequest(requestId, reportId);
    } catch (e) {
      const err = e as AxiosResponse<ApiResponse<unknown>>;
      showErrorToast(dispatch, err?.data?.ErrorId);
      setRequestId(undefined);
      setRequestState(REQUEST_STATE.IDLE);
    }
  };

  const downloadReport = async () => {
    try {
      const result = (await getXlsPeriodicReportFile(requestId!)).data.Result;

      if (result.processingState === PROCESSING_STATE.Completed) {
        downloadFile(result.file, xlsxContentType, result.fileName);
      } else showErrorToast(dispatch);
    } catch (e) {
      const err = e as AxiosResponse<ApiResponse<unknown>>;
      showErrorToast(dispatch, err?.data?.ErrorId);
    }
  };

  const reportDownloadButton = (
    <button className={styles.clearButton} onClick={requestReport} type="button">
      <FileDownloadIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </button>
  );

  const reportPendingIcon = (
    <Tooltip title={tooltipMessage}>
      <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
    </Tooltip>
  );

  const reportReadyButton = (
    <button className={styles.clearButton} onClick={downloadReport} type="button">
      <FileDownloadIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </button>
  );

  return useMemo(
    () => (
      <div className={styles.download}>
        {requestState === REQUEST_STATE.IDLE && reportDownloadButton}
        {requestState === REQUEST_STATE.PENDING && reportPendingIcon}
        {requestState === REQUEST_STATE.READY && reportReadyButton}
      </div>
    ),
    [requestState]
  );
};

export default AsyncDownload;
