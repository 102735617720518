import { FC, useEffect, ChangeEvent, useState } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonProps } from '@mui/material/Button';
import { isEqual } from 'lodash';
import {
  PageTitle,
  TextField,
  Select,
  Dialog,
  PageSubtitle,
  SwitchSection,
  StyledButton,
  Tooltip,
  StyledRadioButton
} from 'components';
import { Props as DialogProps } from 'components/Dialog/Dialog.types';
import { getCompaniesDict } from 'api/services';
import { DictionaryDto } from 'api/models';
import { PATH } from 'router/routes';
import { ROLE, ROLE_MAP } from 'config/oidc';
import { useFetch, useSendRequest, useToggle } from 'lib/hooks';
import { UserFormTypes } from '../types';
import { userSchema } from '../schema';
import { FormControlLabel } from '@mui/material';
import {
  tooltipClientAdmin,
  tooltipClientUser,
  tooltipTechAccount,
  tooltipUserWithoutWebAccess,
  tooltipReportOnlyUser
} from 'domains/user/userProfile';
import { mapRolesToNames } from '../types/userForm.types';
import { getAssignedReports } from 'api/services';

const UserForm: FC<UserFormTypes.Props> = ({ inEditMode, initialValues, submitHandler }) => {
  const [isReportDisableModalOpen, setIsReportDisableModalOpen] = useState<boolean>(false);
  const [isAcceptModalOpen, toggleAcceptModal] = useToggle();
  const [isAssignedReportsInfoModalOpen, toggleAssignedReportsInfoModal] = useToggle();
  const [isCancelModalOpen, toggleCancelModal] = useToggle();
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const userCompany = auth?.userData?.profile?.Company;
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const companyId = userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : userCompany;
  const [reports, setReports] = useState<boolean>(false);

  const {
    result: postResult,
    isLoading: isDataSending,
    validationErrors,
    sendData,
    setValidationErrors
  } = useSendRequest();

  const { result: companiesDict } = useFetch<DictionaryDto>(getCompaniesDict, [], userRole === ROLE.CLIENT_ADMIN);

  const { result: assignedReports } = useFetch<any>(
    useCallback(() => getAssignedReports(id || ''), [id]),
    [],
    inEditMode === false
  );

  const handleSaveAction = () => {
    if (formik.values[UserFormTypes.FIELDS.ROLE] === ROLE_MAP.ReportOnlyUser && reports) {
      toggleAcceptModal();
      toggleAssignedReportsInfoModal();
    } else {
      formik.submitForm();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values, helpers) => {
      sendData(
        () => submitHandler(values),
        undefined,
        inEditMode
          ? () => {
              toggleAcceptModal();
            }
          : undefined
      );
    }
  });

  useEffect(() => {
    if (userRole === ROLE.CLIENT_ADMIN || userRole === ROLE.CURATOR) {
      formik.setFieldValue(UserFormTypes.FIELDS.COMPANY_ID, companyId);
    }
    // eslint-disable-next-line
  }, [userRole, companyId, formik.setFieldValue]);

  useEffect(() => {
    if (assignedReports?.length > 0) {
      setReports(JSON.parse(assignedReports).length > 0);
    }
  }, [assignedReports]);

  useEffect(() => {
    if (!isDataSending && postResult) {
      navigate(`${PATH.USER_PROFILE}/${postResult}`);
    }
  }, [isDataSending, inEditMode, postResult, navigate]);

  const fields: UserFormTypes.FieldType[] = [
    {
      name: UserFormTypes.FIELDS.FIRST_NAME,
      label: 'Imię',
      value: formik.values[UserFormTypes.FIELDS.FIRST_NAME],
      error: formik.touched[UserFormTypes.FIELDS.FIRST_NAME] && !!formik.errors[UserFormTypes.FIELDS.FIRST_NAME],
      helperText: formik.touched[UserFormTypes.FIELDS.FIRST_NAME] && formik.errors[UserFormTypes.FIELDS.FIRST_NAME],
      dataTest: 'user-form-input-firstName'
    },
    {
      name: UserFormTypes.FIELDS.LAST_NAME,
      label: 'Nazwisko',
      value: formik.values[UserFormTypes.FIELDS.LAST_NAME],
      error: formik.touched[UserFormTypes.FIELDS.LAST_NAME] && !!formik.errors[UserFormTypes.FIELDS.LAST_NAME],
      helperText: formik.touched[UserFormTypes.FIELDS.LAST_NAME] && formik.errors[UserFormTypes.FIELDS.LAST_NAME],
      dataTest: 'user-form-input-lastName'
    },
    {
      name: UserFormTypes.FIELDS.EMAIL,
      label: 'Adres e-mail',
      value: formik.values[UserFormTypes.FIELDS.EMAIL],
      error: formik.touched[UserFormTypes.FIELDS.EMAIL] && !!formik.errors[UserFormTypes.FIELDS.EMAIL],
      helperText: formik.touched[UserFormTypes.FIELDS.EMAIL] && formik.errors[UserFormTypes.FIELDS.EMAIL],
      dataTest: 'user-form-input-email'
    },
    {
      name: UserFormTypes.FIELDS.PHONE,
      label: 'Numer telefonu (9 cyfr)',
      value: formik.values[UserFormTypes.FIELDS.PHONE],
      error: formik.touched[UserFormTypes.FIELDS.PHONE] && !!formik.errors[UserFormTypes.FIELDS.PHONE],
      helperText: formik.touched[UserFormTypes.FIELDS.PHONE] && formik.errors[UserFormTypes.FIELDS.PHONE],
      type: 'number',
      dataTest: 'user-form-input-phone'
    }
  ];

  const selectField: UserFormTypes.FieldType = {
    name: UserFormTypes.FIELDS.COMPANY_ID,
    label: 'Firma',
    value: formik.values[UserFormTypes.FIELDS.COMPANY_ID],
    error:
      !formik.values[UserFormTypes.FIELDS.COMPANY_ID] &&
      formik.touched[UserFormTypes.FIELDS.COMPANY_ID] &&
      !!formik.errors[UserFormTypes.FIELDS.COMPANY_ID],
    helperText: formik.touched[UserFormTypes.FIELDS.COMPANY_ID] && formik.errors[UserFormTypes.FIELDS.COMPANY_ID],
    dataTest: 'user-form-input-company'
  };

  const handleSwitchChange = (fieldName: UserFormTypes.FIELDS) => () => {
    if (formik.values[UserFormTypes.FIELDS.ROLE] === ROLE_MAP.UserWithoutWebAccess) {
      if (
        fieldName === UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION ||
        fieldName === UserFormTypes.FIELDS.HAS_CSV_PERMISSION
      )
        return false;
      formik.setFieldValue(fieldName, !formik.values[fieldName]);
    } else if (formik.values[UserFormTypes.FIELDS.ROLE] === ROLE_MAP.TechAccount) {
      return false;
    } else if (formik.values[UserFormTypes.FIELDS.ROLE] === ROLE_MAP.ReportOnlyUser) {
      return false;
    } else {
      formik.setFieldValue(fieldName, !formik.values[fieldName]);
    }
  };

  const handleNumberTypeChanged = (propertyName: string, event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(propertyName, event.target.value.replace(/[^0-9.]/g, ''));
  };

  const onRoleChange = (event: any, field: UserFormTypes.FIELDS) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(field, Number(value));

      if (Number(value) === ROLE_MAP.TechAccount) {
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_CSV_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS, false);
      } else if (Number(value) === ROLE_MAP.ClientAdmin) {
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_CSV_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS, true);
      } else if (Number(value) === ROLE_MAP.ClientUser) {
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_CSV_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS, true);
      } else if (Number(value) === ROLE_MAP.UserWithoutWebAccess) {
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_CSV_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS, true);
      } else if (Number(value) === ROLE_MAP.ReportOnlyUser) {
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION, true);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_CSV_PERMISSION, false);
        formik.setFieldValue(UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS, true);
      }
    }
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      UserFormTypes.FIELDS.FIRST_NAME,
      UserFormTypes.FIELDS.LAST_NAME,
      UserFormTypes.FIELDS.EMAIL,
      UserFormTypes.FIELDS.PHONE,
      UserFormTypes.FIELDS.COMPANY_ID
    ];
    return requiredFields.every((field) => formik.values[field]);
  };

  const isInitialValuesChanged = () => {
    const values = {
      ...initialValues,
      [UserFormTypes.FIELDS.COMPANY_ID]: initialValues[UserFormTypes.FIELDS.COMPANY_ID].toString()
    };

    return isEqual(values, formik.values);
  };

  const shouldFormBeDisabled =
    !checkRequiredFields() ||
    isInitialValuesChanged() ||
    Object.keys(formik.errors).length > 0 ||
    validationErrors.length > 0 ||
    !!postResult ||
    !formik.dirty;

  const closeWindow = () => {
    window.close();
  };

  const acceptButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: shouldFormBeDisabled || isDataSending,
    type: inEditMode ? 'button' : 'submit',
    onClick: inEditMode ? toggleAcceptModal : undefined
  };

  const cancelButtonLabel = inEditMode
    ? 'Czy chcesz anulować wprowadzone zmiany?'
    : 'Czy chcesz anulować dodanie nowego użytkownika?';

  const cancelDialogProps: DialogProps = {
    isOpen: isCancelModalOpen,
    closeModal: toggleCancelModal,
    handlePrimaryAction: inEditMode ? () => navigate(`${PATH.USER_PROFILE}/${id}`) : closeWindow,
    primaryLabel: 'Tak',
    closeLabel: 'Nie'
  };

  const pageTitle = inEditMode ? 'Modyfikacja użytkownika' : 'Nowy użytkownik';
  return (
    <Box sx={{ padding: '1rem 0' }}>
      <PageTitle dataTest="uset-form-page-title" sx={{ padding: '0 1.5rem' }}>
        {pageTitle}
      </PageTitle>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '2.25rem' }}>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <PageSubtitle dataTest="uset-form-page-subtitle" subtitle="Dane użytkownika" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {fields.map((field, index) => {
                return (
                  <TextField
                    key={field.name}
                    field={field}
                    validationErrors={validationErrors}
                    onChangeHandler={(e) =>
                      field.type === 'number' ? handleNumberTypeChanged(field.name, e) : formik.handleChange(e)
                    }
                    onBlurHandler={formik.handleBlur}
                    errorsSetter={setValidationErrors}
                    autoFocus={index === 0}
                    disabled={!!postResult}
                    dataTest={field.dataTest}
                  />
                );
              })}
              {[ROLE.ADMIN_KG, ROLE.INTEGRATOR].includes(userRole) && (
                <Select
                  field={selectField}
                  options={companiesDict?.dictionary.sort((a, b) => a.value.localeCompare(b.value)) || []}
                  validationErrors={validationErrors}
                  onClick={() => {
                    formik.setFieldTouched(UserFormTypes.FIELDS.COMPANY_ID);
                  }}
                  onChangeHandler={formik.handleChange}
                  errorsSetter={setValidationErrors}
                  disabled={inEditMode || !!postResult}
                  dataTest={selectField.dataTest}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '4rem 0 0 0' }}></Box>
          <Box sx={{ padding: '3rem 1.5rem 0 1.5rem', width: '60%' }}>
            <PageSubtitle subtitle="Rola użytkownika" />
            <Box sx={{ margin: '1rem 0 1.5rem .25rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={(event) => onRoleChange(event, UserFormTypes.FIELDS.ROLE)}
                  value={ROLE_MAP.ClientAdmin}
                  name={'role'}
                  key={ROLE_MAP.ClientAdmin}
                  control={
                    <StyledRadioButton
                      id="user-form-radio-admin"
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.ClientAdmin}
                    />
                  }
                  label={mapRolesToNames(ROLE_MAP.ClientAdmin)}
                  disabled={inEditMode && !formik.values[UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]}
                />
                <Tooltip text={tooltipClientAdmin} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={(event) => onRoleChange(event, UserFormTypes.FIELDS.ROLE)}
                  value={ROLE_MAP.ClientUser}
                  name={'role'}
                  key={ROLE_MAP.ClientUser}
                  control={
                    <StyledRadioButton
                      id="user-form-radio-worker"
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.ClientUser}
                    />
                  }
                  label={mapRolesToNames(ROLE_MAP.ClientUser)}
                  disabled={inEditMode && !formik.values[UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]}
                />
                <Tooltip text={tooltipClientUser} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={(event) => onRoleChange(event, UserFormTypes.FIELDS.ROLE)}
                  value={ROLE_MAP.UserWithoutWebAccess}
                  name={'role'}
                  key={ROLE_MAP.UserWithoutWebAccess}
                  control={
                    <StyledRadioButton
                      id="user-form-radio-user-no-access"
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.UserWithoutWebAccess}
                    />
                  }
                  label={mapRolesToNames(ROLE_MAP.UserWithoutWebAccess)}
                  disabled={inEditMode && !formik.values[UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]}
                />
                <Tooltip text={tooltipUserWithoutWebAccess} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={(event) => onRoleChange(event, UserFormTypes.FIELDS.ROLE)}
                  value={ROLE_MAP.TechAccount}
                  name={'role'}
                  key={ROLE_MAP.TechAccount}
                  control={
                    <StyledRadioButton
                      id="user-form-radio-user-tech-account"
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.TechAccount}
                    />
                  }
                  label={mapRolesToNames(ROLE_MAP.TechAccount)}
                  disabled={inEditMode && Number(formik.values[UserFormTypes.FIELDS.ROLE]) !== ROLE_MAP.TechAccount}
                />
                <Tooltip text={tooltipTechAccount} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={(event) => onRoleChange(event, UserFormTypes.FIELDS.ROLE)}
                  value={ROLE_MAP.ReportOnlyUser}
                  name={'role'}
                  key={ROLE_MAP.ReportOnlyUser}
                  control={
                    <StyledRadioButton
                      id="user-form-radio-only-report-user"
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.ReportOnlyUser}
                    />
                  }
                  label={mapRolesToNames(ROLE_MAP.ReportOnlyUser)}
                  disabled={inEditMode && !formik.values[UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]}
                />
                <Tooltip text={tooltipReportOnlyUser} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '2rem 0 0 0' }}></Box>
          <Box sx={{ padding: '3rem 1.5rem', width: '60%' }}>
            <PageSubtitle subtitle="Uprawnienia dodatkowe" />
            <Box sx={{ margin: '1.5rem 0 0 0' }}>
              <SwitchSection
                label="Włączanie i wyłączanie monitorowania klienta"
                borderRadius="0.25rem 0.25rem 0 0"
                isChecked={!!formik.values[UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION]}
                onChange={handleSwitchChange(UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION)}
                disabled={!!postResult}
                dataTest="user-form-switch-monitoring"
              />
              <SwitchSection
                label="Możliwość pobierania aktualnego raportu o kliencie"
                borderRadius="0 0 0.25rem 0.25rem"
                borderTop="none"
                isChecked={!!formik.values[UserFormTypes.FIELDS.HAS_REPORT_PERMISSION]}
                onChange={() => {
                  !!formik.values[UserFormTypes.FIELDS.HAS_REPORT_PERMISSION] &&
                  !(Number(formik.values[UserFormTypes.FIELDS.ROLE]) === ROLE_MAP.ReportOnlyUser)
                    ? setIsReportDisableModalOpen(true)
                    : handleSwitchChange(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION)();
                }}
                disabled={!!postResult}
                dataTest="user-form-switch-reports"
              />
              <SwitchSection
                label="​Możliwość zarządzania importem danych z CSV"
                borderRadius="0 0 0.25rem 0.25rem"
                borderTop="none"
                isChecked={!!formik.values[UserFormTypes.FIELDS.HAS_CSV_PERMISSION]}
                onChange={handleSwitchChange(UserFormTypes.FIELDS.HAS_CSV_PERMISSION)}
                disabled={!!postResult}
                dataTest="user-form-switch-import"
              />
            </Box>
          </Box>
        </Box>
        <Stack spacing={3} direction="row" sx={{ padding: '1.5rem 1.5rem 0.5rem 1.5rem', textTransform: 'uppercase' }}>
          <StyledButton {...acceptButtonProps} data-test-id="user-form-save-button">
            Zapisz
          </StyledButton>
          <StyledButton variant="text" onMouseDown={toggleCancelModal} data-test-id="user-form-cancel-button">
            Anuluj
          </StyledButton>
        </Stack>
      </form>

      {inEditMode && (
        <Dialog
          isOpen={[...validationErrors].length === 0 && isAcceptModalOpen}
          isPending={isDataSending}
          closeModal={toggleAcceptModal}
          handlePrimaryAction={handleSaveAction}
          primaryLabel="Tak"
          closeLabel="Nie">
          Czy chcesz zapisać wprowadzone zmiany?
        </Dialog>
      )}

      {inEditMode && (
        <Dialog
          isOpen={[...validationErrors].length === 0 && isAssignedReportsInfoModalOpen}
          isPending={isDataSending}
          closeModal={toggleAssignedReportsInfoModal}
          closeLabel="OK">
          Użytkownik jest odbiorcą co najmniej jednego cyklicznego raportu e-mail.
          <br />
          W celu zmiany roli na "Użytkownik tylko raportów o NIP" należy usunąć tego użytkownika z list odbiorców
          cyklicznych raportów e-mail.
          <br />
          Wykaz cyklicznych raportów e-mail, których odbiorcą jest dany użytkownik dostępny jest w karcie użytkownika w
          sekcji "Raporty cykliczne e-mail".
        </Dialog>
      )}

      <Dialog
        isOpen={isReportDisableModalOpen}
        closeModal={() => setIsReportDisableModalOpen(false)}
        handlePrimaryAction={() => {
          setIsReportDisableModalOpen(false);
          handleSwitchChange(UserFormTypes.FIELDS.HAS_REPORT_PERMISSION)();
        }}
        primaryLabel="Tak"
        closeLabel="Nie">
        <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
          Czy chcesz wyłączyć uprawnienie 'Możliwość pobierania aktualnego raportu o kliencie'?
        </Box>
        Użytkownik nie będzie miał możliwości pobierania raportu w serwisie RiskRadar oraz w raporcie e-mail z listą
        klientów
      </Dialog>

      <Dialog {...cancelDialogProps}>{cancelButtonLabel}</Dialog>
    </Box>
  );
};

export default UserForm;
