import axiosInstance from 'api/axiosInstance';
import { baseAPIUrl } from 'api/config';
import { ApiResponse } from 'api/models';
import { AxiosPromise } from 'axios';

export const addPeriodicReportXlsFileRequest = (
  requestId: string,
  reportId: number
): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/PeriodicReportFileRequests/Xls`,
    method: 'POST',
    data: {
      requestId,
      reportId
    }
  });
};
