import axiosInstance from 'api/axiosInstance';
import { baseAPIUrl } from 'api/config';
import { ApiResponse, GetPeriodicReportFileQueryResult } from 'api/models';
import { AxiosPromise } from 'axios';

export const getXlsPeriodicReportFile = (id: string): AxiosPromise<ApiResponse<GetPeriodicReportFileQueryResult>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/PeriodicReportFiles/${id}`,
    method: 'GET'
  });
};
