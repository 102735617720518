import { Dispatch } from 'react';
import { resetErrors, setErrors } from 'store/errors';
import { ERRORS } from 'types/errors';

const defaultErrorCode = ERRORS.ERROR_UNEXPECTED;

const defaultErrorId = '00000000-0000-0000-0000-000000000000';

const defaultErrorMessage =
  'Wystąpił nieoczekiwany błąd. Skontaktuj się z administratorem poprzez email admin@riskradar.pl.';

export const showErrorToast = (
  dispatch: Dispatch<any>,
  errorId: string = defaultErrorId,
  message: string = defaultErrorMessage,
  errorCode: string = defaultErrorCode
) => {
  dispatch(resetErrors());

  dispatch(
    setErrors({
      Errors: [
        {
          ErrorCode: errorCode,
          Message: message
        }
      ],
      ErrorId: errorId
    })
  );
};
