export type GetPeriodicReportFileQueryResult = {
  requestId: string;
  processingState: PROCESSING_STATE;
  fileName: string;
  file: string;
};

export enum PROCESSING_STATE {
  Created = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4
}
