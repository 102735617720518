export const downloadFile = (base64: string, contentType: string, fileName: string) => {
  const blob = b64toBlob(base64, contentType);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

const b64toBlob = (b64Data: string, contentType: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
